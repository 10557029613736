import React from 'react'

const PrivacyPolicyPage = () => {
  return (<>
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> 15 February 2025</p>
      <p>Welcome to Sumeo! Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you use our platform.</p>
      <hr />
      
      <h2>1. Information We Collect</h2>
      <h3>1.1 Personal Information</h3>
      <ul>
          <li>Name, email address, and contact details.</li>
          <li>Account credentials (such as username and password).</li>
          <li>Payment and billing information (when applicable).</li>
      </ul>
      
      <h3>1.2 Property-Related Data</h3>
      <ul>
          <li>Property details such as address, purchase price, and financial data.</li>
          <li>Rental agreements, tenant information, and related documents.</li>
          <li>Service provider contact details (e.g., notaries, real estate agents).</li>
      </ul>
      
      <h3>1.3 Usage Data</h3>
      <ul>
          <li>Log files, device information, and IP addresses.</li>
          <li>Cookies and tracking technologies to enhance user experience.</li>
      </ul>
      
      <hr />
      <h2>2. How We Use Your Information</h2>
      <ul>
          <li>Provide and improve our services.</li>
          <li>Process payments and manage financial transactions.</li>
          <li>Enhance security and prevent fraud.</li>
          <li>Send important notifications regarding account activities.</li>
          <li>Offer customer support and resolve technical issues.</li>
          <li>Comply with legal and regulatory requirements.</li>
      </ul>
      
      <hr />
      <h2>3. Data Sharing and Disclosure</h2>
      <p>We do not sell your personal information. However, we may share data with:</p>
      <ul>
          <li><strong>Service Providers:</strong> Third-party vendors assisting in hosting, payment processing, and analytics.</li>
          <li><strong>Legal Authorities:</strong> When required by law or to protect our rights.</li>
          <li><strong>Business Transfers:</strong> In case of a merger, acquisition, or asset sale.</li>
      </ul>
      
      <hr />
      <h2>4. Data Security</h2>
      <p>We implement industry-standard security measures to protect your information, including encryption, access controls, and secure storage. However, no system is entirely foolproof, and we cannot guarantee absolute security.</p>
      
      <hr />
      <h2>5. Your Rights and Choices</h2>
      <p>Depending on your location, you may have the following rights:</p>
      <ul>
          <li><strong>Access & Correction:</strong> Review and update your personal information.</li>
          <li><strong>Deletion:</strong> Request the removal of your data.</li>
          <li><strong>Objection:</strong> Opt-out of certain processing activities.</li>
          <li><strong>Data Portability:</strong> Obtain a copy of your data in a structured format.</li>
      </ul>
      <p>To exercise your rights, contact us at contact@sumeo.app</p>
      
      <hr />
      <h2>6. Retention of Information</h2>
      <p>We retain your data as long as necessary to fulfill our services and legal obligations. Once no longer needed, we securely delete or anonymize the information.</p>
      
      <hr />
      <h2>7. Cookies and Tracking Technologies</h2>
      <p>We use cookies and similar technologies to improve user experience, analyze trends, and personalize content. You can manage cookie preferences in your browser settings.</p>
      
      <hr />
      <h2>8. Third-Party Links</h2>
      <p>Our platform may contain links to third-party websites. We are not responsible for their privacy practices and encourage you to review their policies.</p>
      
      <hr />
      <h2>9. Changes to This Policy</h2>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify users of significant updates.</p>
      
      <hr />
      <h2>10. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at:</p>
      <p><strong>Email:</strong> contact@sumeo.app</p>
    </>
)}

export default PrivacyPolicyPage